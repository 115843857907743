<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
   
        <banner
          title="Staff PROFIL"
          :breadcrumb="[
             {
              label: 'Dashboard',
            },
            {
              label: 'Users',
            },
            {
              label: 'Staff',
            },
            { label: 'Profile' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
          <div
            class="card custom-card"
            style="
              height: 100%;
          
            "
          >
            <div class="card-body pt-2">
              <div class="row">
                <div class="col">
                  <h4 class="d-xl-none d-md-none d-block text-center">
                    <b style="color: #00364f; font-weight: bolder">staff Profile</b>
                  </h4>
                  <h4 class="d-none d-xl-block d-md-block text-start">
                    <b style="color: #00364f; font-weight: bolder">staff Profile</b>
                  </h4>
                </div>
              </div>

              <div class="row">
                <!-- img responsive mobile  -->
                <div
                  class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                >
                  <img
                    style="width: 100px; height: 100px; border-radius: 15px"
                    :src="photo"
                    alt=""
                  />
                  <br />
                  <span style="color: #00364f">
                    <p
                      v-if="staff.loginStatus == 'true'"
                      class="btn btn-sm"
                      style="
                        width: 100px;
                        background-color: green;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>Active</b>
                    </p>
                    <p
                      v-else
                      class="btn btn-sm"
                      style="
                        width: 30%;
                        background-color: red;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>InActive</b>
                    </p>
                  </span>
                </div>
                <h5 class="text-start">
                  <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                </h5>

                <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Code : </b>
                    <span v-if="staff">{{ staff.code }} </span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Name : </b>
                    <span v-if="staff">{{ staff.name }} </span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>DOB : </b>
                    <span v-if="staff">{{ dateTime(staff.dob) }}</span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Gender : </b>
                    <span v-if="staff" style="text-transform: capitalize"
                      >{{ staff.gender }}
                    </span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Phone Number : </b>
                    <span v-if="staff">+91-{{ staff.phone }}</span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Alt Phone Number : </b>
                    <span v-if="staff">+91-{{ staff.alternativePhone }}</span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Email Id : </b>
                    <span v-if="staff">{{ staff.email }}</span>
                  </div>
                </div>
                <!-- img responsive Pc  -->
                <div
                  class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                >
                  <img
                    style="width: 100%; border-radius: 15px; margin-top: -20px"
                    :src="photo"
                    alt=""
                  />
                  <span style="color: #00364f; width: 40%">
                    <p
                      v-if="staff.loginStatus == 'true'"
                      class="btn btn-sm"
                      style="
                        width: 100%;
                        background-color: green;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>Active</b>
                    </p>
                    <p
                      v-else
                      class="btn btn-sm"
                      style="
                        width: 100%;
                        background-color: red;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>InActive</b>
                    </p>
                  </span>
                </div>
              </div>
              <div class="col-12" style="margin-left: 7px">
                <div class="text">
                  <img
                    style="width: 8px; margin-right: 5px"
                    src="/assets/image/record.png"
                    alt=""
                  />
                  <b>Address : </b>
                  <span v-if="staff">{{ staff.address }}  {{ staff.city }}  {{ staff.pin }} </span>
                </div>
                <div class="text">
                  <img
                    style="width: 8px; margin-right: 5px"
                    src="/assets/image/record.png"
                    alt=""
                  />
                  <b>State : </b>
                  <span v-if="staff.state">{{ staff.state.name }}</span>
                </div>


                <div class="text">
                  <img
                    style="width: 8px; margin-right: 5px"
                    src="/assets/image/record.png"
                    alt=""
                  />
                  <b>Remark : </b>
                  <span v-if="staff">{{ staff.remark }}</span>
                </div>
              </div>
              <!-- bussiness Detais  -->
              <div class="row">
                <h5 class="text-start mt-2">
                  <b style="color: #f21300; font-weight: bolder">Kyc Details </b>
                </h5>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>PAN : </b>
                    <span v-if="staff.staff_kyc"
                      >{{ staff.staff_kyc.panNo }}
                    </span>
                  </div>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Aadhaar Number : </b>
                    <span v-if="staff.staff_kyc"
                      >{{ staff.staff_kyc.aadharNo }}
                    </span>
                  </div>
                  <!-- <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Account Number : </b>
                                            <span v-if="staff.staff_kyc">{{ staff.staff_kyc.accountNo }} </span>
                                        </div> -->
                </div>
              </div>
              <!-- end  bussiness Detais  -->

              <!-- Qualifications -->
              <div class="row">
                <h5 class="text-start mt-2">
                  <b style="color: #f21300; font-weight: bolder">Designation </b>
                </h5>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <span v-if="staff.designation"
                      >{{ staff.designation.name }}
                    </span>
                  </div>
                </div>
              </div>

              <hr />
              <div class="row mt-1">
                <div class="col-12 text-end">
                  <!-- <button
                    @click="valueAssingForProfile()"
                    type="button"
                    class="btn text-white btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#profileUpdateModel"
                    style="background-color: #00364f; margin-right: 5px"
                  >
                    <b
                      >Edit Profile &nbsp;
                      <i class="fa fa-edit"></i>
                    </b>
                  </button> -->
                  <button
                  @click="valueAssingForProfile()"
                    type="button"
                    class="btn text-white btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#doccuments"
                    style="background-color: #f21300; margin-right: 5px"
                  >
                    <b
                      >Kyc Doccuments &nbsp;
                      <i class="fa fa-hand-pointer-o"></i>
                    </b>
                  </button>
                  <!-- <button type="button" class="btn btns text-white btn-sm"><b>Edit Profile &nbsp;
                        <font-awesome-icon icon="edit" />
                      </b></button> -->
                </div>
              </div>
              <div
                class="row mt-1"
                style="background-color: #e3e3e3; border-radius: 15px"
              >
                <div class="col-12 p-1">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>IP Address: </b>
                    <span v-if="staff">{{ staff.ipAddress }}</span>
                  </div>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Last Login: </b>
                    <span v-if="staff">{{ dateTimeWithTime(staff.last_login) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm text">
              <tr class="table-wrapper-scroll-y tr-head rounded-circle">
                <th>+</th>
                <th>Name</th>
                <th class="text-end">Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; margin-right: 5px"
                      :src="adharFrontImg"
                      alt=""
                    />
                  </td>

                  <td><p>Aadhar Front</p></td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharFront.toLowerCase() == 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; margin-right: 5px"
                      :src="adharBackImg"
                      alt=""
                    />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack.toLowerCase() == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      s
                      style="width: 30px; height: 30px; margin-right: 5px"
                      :src="panImg"
                      alt=""
                    />
                  </td>

                  <td>
                    <p>Pan Card</p>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycPanPhoto.toLowerCase() == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- wallet staff -->
</template>

//
<script>
import moment from "moment";
import Banner from "../../../components/staff/comman/Banner.vue";

import Spinner from "../../../components/staff/comman/Spinner.vue";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery;
export default {
  name: "staffProfileStaff",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      panImgPdf: "",
      adharBackImgPdf: "",
      adharFrontImgPdf: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      loading: false,
      form_id: null,
      photo: this.$store.state.placeholderImg,

      state: {},
      staffkyc: [],
      beneficiaryaccounts: [],
      msg: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      // passBookImg: this.$store.state.placeholderImg,
      // passBookImgUrl: "",
      staff: {},
      wallet: {},
      edit: "false",
      form: new Form({
        bank_name: "",
        account_number: "",
        name: "",
        phone: "",
        ifsc: "",
        remark: "",
      }),
    };
  },
  methods: {
    openWindow(url) {
      window.open(`${url}`);
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
  

    loadRetailers() {
      // this.loading = true;
      this.$axios
        .get(`staff/staff/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("______________staff______________________");
          this.staff = res.data.data;

          console.log(this.staff);

          if (this.staff.photo != null) {
            this.photo =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/thumbs/" +
              this.staff.photo;
          } else {
            this.photo = this.$store.state.placeholderImg;
          }

      //     if (this.staff.staff_kyc.panPhoto != null) {
      //   this.panImg =
      //     this.$store.state.imgUrl +
      //     "/staff/" +
      //     this.staff.id +
      //     "/thumbs/" +
      //     this.staff.staff_kyc.panPhoto;
      // } else {
      //   this.panImg = this.$store.state.placeholderImg;
      // }

 
      
      if (this.staff.staff_kyc.panPhoto != null) {
        
        if (
          this.$store.state.imgUrl +
          "/staff/" +
          this.staff.id +
          "/thumbs/" +
          this.staff.staff_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/staff/" +
            this.staff.id +
            "/thumbs/" +
            this.staff.staff_kyc.panPhoto;
        }

        if (
          this.$store.state.imgUrl +
          "/staff/" +
          this.staff.id +
          "/" +
          this.staff.staff_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/staff/" +
            this.staff.id +
            "/" +
            this.staff.staff_kyc.panPhoto;
        }
        this.panImgPdf = this.panImg
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

    

      if (this.staff.staff_kyc.aadharFrontPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staff.id +
            "/thumbs/"+
            this.staff.staff_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/thumbs/" +
              this.staff.staff_kyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staff.id +
            "/" +
            this.staff.staff_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/" +
              this.staff.staff_kyc.aadharFrontPhoto;
          }

          this.adharFrontImgPdf = this.adharFrontImg
          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }


  // aadhar Back photo
  if (this.staff.staff_kyc != null) {
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staff.id +
            "/thumbs/" +
            this.staff.staff_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/thumbs/" +
              this.staff.staff_kyc.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staff.id +
            "/" +
            this.staff.staff_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/" +
              this.staff.staff_kyc.aadharBackPhoto;
          }
          this.adharBackImgPdf = this.adharBackImg
          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }

        });
      // .finally(() => (this.loading = false));
    },
    loadPageTitle(){
        document.title =this.$store.state.staffPageTitles.staffView
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {

      this.loadRetailers();
      this.loadPageTitle()

    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-transform: capitalize;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #e70c0c;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
